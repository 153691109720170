import React from "react"

import Layout from "../components/layout"
import Header from "../components/header"
import PostMarkDown from '../components/PostMarkdown'

const About = () => (
  <Layout>
    <Header />
    <div className="center" style={{ padding: "2rem", marginTop: "3rem", display: "flex", justifyContent: "center",maxWidth: "900px" }}>

      <div>
        <h1>About Me</h1>
        <h3>CL Deepak, The Founder of behindjava.com</h3>
        <p>behindjava.com is a multidisciplinary website based on Java, J2EE, Springboot, Microservices, Python and etc tutorials.</p>
        <p>This website is light, easy to read, and filled with more than 500+ tutorials which are important concepts that are used in software development activities.</p>
        <p>behindjava main mottoes and aim, is to make the beginners, developers and aspiring Java learners understand all the important requirements and concepts in an easy way with possible real-time examples.</p>
        <p>You can buy us a coffee at : https://www.buymeacoffee.com/behindjava</p>
        <p>If you find any mistakes or bugs, Please drop an email to behindjava@gmail.com</p>
      </div>

    </div>
  </Layout>
)

export default About